/**
 * 
 * No page found component
 * @author - NA 
 * @date - 1st March, 2024
 * 
 */
// GENERIC IMPORT
import React from 'react';

const NoPage = () => (<>NoPage</>);

export default NoPage;