export const EXPERIMENT_SUBMIT_API = "/experiments/start-task";
export const EXPERIMENT_LIST_API = "/experiments/get-list";
export const UPDATE_EXPERIMENT_API = "/image/generate";
export const GET_EXPERIMENT_BY_ID_API = "/experiments/result/";
export const LOGIN_API = "/experiments/auth";

export const JSONHeader = {
    headers: {
        'Content-Type': 'application/json',
    },
}